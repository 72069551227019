
import { PageBase } from '@/core/models/shared';
import { RelatorioService } from '@/core/services/financeiro';
import { Component } from 'vue-property-decorator';

@Component
export default class RelTaxaBoleto extends PageBase {
  service = new RelatorioService();
  item = new RelatorioTaxaBoletoModel();
  overlay: boolean = false;
  filter: any = {};

  mounted() {
    this.filter = this.$route.query;
    this.overlay = true;

    this.service.ListarRelatorioTaxaBoleto(this.filter.empreendimentoId, this.filter.empresaId,this.filter.numeroContrato, this.filter.dataVencimentoInicial, this.filter.dataVencimentoFinal, this.filter.dataPagamentoInicial, this.filter.dataPagamentoFinal).then(
        res => {
            this.item = res.data;  
        }
        ).catch(err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      })
      .finally(() => {
        this.overlay = false;
      });
  }
}

class RelatorioTaxaBoletoModel {
    public parcelas:RelatorioTaxaBoletoParcelaModel[] = []; 
    public totalParcelas:number = 0;
    public totalPagoTarifa:number = 0;
}

class RelatorioTaxaBoletoParcelaModel { 
    public contratoNumero:number = 0;
    public contratoSequencia:number = 0;
    public empreendimentoNome:string = "";
    public grupoNome:string = "";
    public unidadeNome:string = "";
    public parcelaNumero:number = 0;
    public parcelaValorTaxaBoleto:number = 0;
    public dataPagamento:string = "";
    public dataVencimento:string = "";
    public contratoNumeroSequencia:string = "";
}
